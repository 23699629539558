import React from 'react';
import { withRouter } from '../utils/Utils';
import Utils from '../utils/Utils';
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import {Nav,ButtonToolbar,FormControl,Button,Badge} from 'react-bootstrap';

class AccountContainer extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			account: {},
			transactionEntries: [],
			clearedBalance: 0,
			clearFilter: "unclear",
			transaction: {
				payee: "",
				in: "0",
				out: "0",
				date: moment().format('YYYY-MM-DD')
			},
			editTransactionId: null,
			editTransaction: { },
			loading: false,
			selected: {}
		};
		this.handleChangeClearFilter = this.handleChangeClearFilter.bind(this);
		this.deleteClicked = this.deleteClicked.bind(this);
		this.handleChangePayee = this.handleChangePayee.bind(this);
		this.handleChangeIn = this.handleChangeIn.bind(this);
		this.handleChangeOut = this.handleChangeOut.bind(this);
		this.handleChangeDate = this.handleChangeDate.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleDoubleClick = this.handleDoubleClick.bind(this);
		this.handleEditChangePayee = this.handleEditChangePayee.bind(this);
		this.handleEditChangeIn = this.handleEditChangeIn.bind(this);
		this.handleEditChangeOut = this.handleEditChangeOut.bind(this);
		this.handleEditChangeDate = this.handleEditChangeDate.bind(this);
		this.handleEditSubmit = this.handleEditSubmit.bind(this);
		this.handleEditCancel = this.handleEditCancel.bind(this);
		this.handleChangeSelected = this.handleChangeSelected.bind(this);
		this.handleDeleteSelected = this.handleDeleteSelected.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
		this.handleSelectAllChecked = this.handleSelectAllChecked.bind(this);
	}

	componentDidMount() {

		let _this = this;
		let accountId = _this.props.params.accountId;

		_this.setState({ loading: true });

		fetch(Utils.serviceUrl(`/accounts/${accountId}`))
			.then(function (response) {
				response.json().then(function (data) {
					_this.setState({account: data});
				}, function () {

				});
			});

		_this.getTransactions(_this)

	}

	getTransactions(_this) {

		let accountId = _this.props.params.accountId;

		let url = Utils.serviceUrl(`/accounts/${accountId}/transactions`);

		if (this.state.clearFilter === "clear") {
			url += "?clear=true"
		} else if (this.state.clearFilter === "unclear") {
			url += "?clear=false"
		}

		_this.setState({ loading: true }, function () {
			fetch(url)
				.then(function (response) {
					response.json().then(function (data) {
						let selected = _this.state.selected;
						let transactionIds = data.results.map(entry => entry.transaction.transactionId);
						transactionIds.forEach(transactionId => {
							if (!(transactionId in selected)) {
								selected[transactionId] = false
							}
						});
						Object.keys(selected).forEach(transactionId => {
							if(!(transactionIds.includes(transactionId))) {
								delete selected[transactionId];
							}
						});
						_this.setState({
							transactionEntries: data.results,
							clearedBalance: data.clearedBalance,
							selected: selected
						}, function () {
							_this.setState({ loading: false });
						});
					});
				});

		});

	}

	handleChangeClearFilter(event) {
		let _this = this;
		this.setState({
			clearFilter: event.target.value
		}, function () {
			_this.getTransactions(_this);
		});
	}

	handleSubmit(event) {

		let _this = this;
		event.preventDefault();

		let transaction = this.state.transaction;

		let input = transaction.in && transaction.in !== "0" ? transaction.in : transaction.out;
		transaction.amount = Utils.moneyGetAmountFromDecimal(input, this.state.account.currency);
		if(transaction.out && transaction.out !== "0") transaction.amount = -transaction.amount;
		delete transaction.in;
		delete transaction.out;

		_this.setState({ loading: true }, function () {
			fetch(Utils.serviceUrl(`/accounts/${this.state.account.accountId}/transactions`), {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(transaction)
			}).then(response => {
				_this.getTransactions(_this);
				_this.setState({
					transaction: {
						payee: "",
						in: "0",
						out: "0",
						date: moment().format('YYYY-MM-DD')
					}
				});
			});

		});
	}

	handleChangePayee(event) {
		let transaction = this.state.transaction;
		transaction.payee = event.target.value;
		this.setState({
			transaction: transaction
		});
	}

	handleChangeIn(event) {
		let transaction = this.state.transaction;
		transaction.in = event.target.value.trim().replace(/[^0-9.]/g, '')
		transaction.out = "0";
		this.setState({
			transaction: transaction
		});
	}

	handleChangeOut(event) {
		let transaction = this.state.transaction;
		transaction.in = "0";
		transaction.out = event.target.value.trim().replace(/[^0-9.]/g, '')
		this.setState({
			transaction: transaction
		});
	}

	handleChangeDate(event) {
		let transaction = this.state.transaction;
		transaction.date = event.target.value;
		this.setState({
			transaction: transaction
		});
	}

	deleteClicked(event) {

		let _this = this;
		event.preventDefault();

		let accountId = this.state.account.accountId;
		let transactionId = event.currentTarget.dataset.id;

		_this.setState({ loading: true }, function () {
			fetch(Utils.serviceUrl(`/accounts/${accountId}/transactions/${transactionId}`), {
				method: 'DELETE'
			}).then(response => {
				_this.getTransactions(_this);
			});
		});

	}

	static rowClassName(context, entry) {
		var classes = '';
		if(entry.balance < 0) {
			classes += ' danger';
		} else if(typeof context.state !== 'undefined' &&
			typeof context.state.selected !== 'undefined' &&
			entry.transaction.transactionId in context.state.selected &&
			context.state.selected[entry.transaction.transactionId]) {
			classes += ' info';
		}
		return classes;
	}

	handleDoubleClick(event) {
		event.preventDefault();
		let transactionId = event.currentTarget.dataset.id;
		let transaction = this.state.transactionEntries.map(t => t.transaction).find(t => t.transactionId === transactionId);
		this.setState({
			editTransactionId: transactionId,
			editTransaction: {
				payee: transaction.payee,
				in: transaction.amount >= 0 ? Utils.moneyFormatAmountFromIntegerNoComma(transaction.amount, this.state.account.currency) : "0",
				out: transaction.amount < 0 ? Utils.moneyFormatAmountFromIntegerNoComma(-transaction.amount, this.state.account.currency) : "0",
				date: transaction.date
			}
		});
	}

	handleEditChangeDate(event) {
		let editTransaction = this.state.editTransaction;
		editTransaction.date = event.target.value;
		this.setState({
			editTransaction: editTransaction
		});
	}

	handleEditChangePayee(event) {
		let editTransaction = this.state.editTransaction;
		editTransaction.payee = event.target.value;
		this.setState({
			editTransaction: editTransaction
		});
	}

	handleEditChangeOut(event) {
		let editTransaction = this.state.editTransaction;
		editTransaction.out = event.target.value.trim().replace(/[^0-9.]/g, '')
		editTransaction.in = "0";
		this.setState({
			editTransaction: editTransaction
		});
	}

	handleEditChangeIn(event) {
		let editTransaction = this.state.editTransaction;
		editTransaction.out = "0";
		editTransaction.in = event.target.value.trim().replace(/[^0-9.]/g, '')
		this.setState({
			editTransaction: editTransaction
		});
	}

	handleEditCancel(event) {
		event.preventDefault();
		this.setState({
			editTransactionId: null,
			editTransaction: { }
		});
	}

	handleEditSubmit(event) {

		event.preventDefault();

		let transaction = this.state.editTransaction;

		let input = transaction.in && transaction.in !== "0" ? transaction.in : transaction.out;
		transaction.amount = Utils.moneyGetAmountFromDecimal(input, this.state.account.currency);

		if(transaction.out && transaction.out !== "0") transaction.amount = -transaction.amount;

		delete transaction.in;
		delete transaction.out;

		let accountId = this.state.account.accountId;
		let transactionId = this.state.editTransactionId;

		let _this = this;

		this.setState({ loading: true }, function () {
			fetch(Utils.serviceUrl(`/accounts/${accountId}/transactions/${transactionId}`), {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(transaction)
			}).then(response => {
				_this.getTransactions(_this);
				this.setState({
					editTransactionId: null,
					editTransaction: { }
				});
			});
		});

	}

	handleChangeSelected(event) {

		let transactionId = event.currentTarget.dataset.id;
		let selected = this.state.selected;

		selected[transactionId] = event.target.checked;

		this.setState({
			selected: selected
		});
	}

	countSelected() {
		return Object.keys(this.state.selected).map(key => this.state.selected[key]).filter(v => v).length;
	}

	handleSelect(event) {
		event.preventDefault();
		event.target.select();
	}

	handleDeleteSelected(event) {

		let _this = this;
		event.preventDefault();

		let accountId = this.state.account.accountId;
		let transactionIds = Object.keys(this.state.selected).filter(key => this.state.selected[key]);

		console.dir(this.state);

		this.setState({ loading: true });

		fetch(Utils.serviceUrl(`/accounts/${accountId}/batchTransactions`), {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				transactionIds: transactionIds
			})
		})
		.then(function (response) {
			_this.getTransactions(_this);
		});

	}

	handleSelectAllChecked(event) {
		let _this = this;

		let select = event.target.checked;
		let transactionIds = _this.state.transactionEntries.map(t => t.transaction).map(t => t.transactionId);

		let selected = this.state.selected;

		transactionIds.forEach(transactionId => {
			selected[transactionId] = select;
		});

		this.setState({
			selected: selected
		});

	}

	render() {
		return (
			<div>

				<div className="mb-4 border-bottom">
					<div className="actions float-right">
						<ButtonToolbar>
							{this.countSelected() > 0 &&
								<Button type="submit" variant="danger" onClick={this.handleDeleteSelected}>Delete</Button>
							}
							<Button href={`/accounts/${this.state.account.accountId}/schedule`}>Add Transactions</Button>
						</ButtonToolbar>
					</div>
					<h1>
						{this.state.account.name} {this.state.loading && <small><span className="glyphicon glyphicon-refresh glyphicon-spin"/></small>}
					</h1>
				</div>

				<Nav variant="tabs" activeKey="1">
					<Nav.Item>
						<Nav.Link eventKey="1" href={`/accounts/${this.state.account.accountId}/transactions`}>Transactions</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link eventKey="2" href={`/accounts/${this.state.account.accountId}/scheduledTransactions`}>Schedule</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link eventKey="3" href={`/accounts/${this.state.account.accountId}/reconcile`}>Reconcile</Nav.Link>
					</Nav.Item>
				</Nav>

				<div className="top-margin float-right">
					<Form>
						<Form.Check type='radio' name="clearFilter" value="all" checked={this.state.clearFilter === "all"} onChange={this.handleChangeClearFilter} inline label='All'/>
						<Form.Check type='radio' name="clearFilter" value="clear" checked={this.state.clearFilter === "clear"} onChange={this.handleChangeClearFilter} inline label='Cleared'/>
						<Form.Check type='radio' name="clearFilter" value="unclear" checked={this.state.clearFilter === "unclear"} onChange={this.handleChangeClearFilter} inline label='Uncleared'/>
					</Form>
				</div>

				<div>
					<Table striped hover responsive>
						<thead>
						<tr>
							<th className="width40"/>
							<th className="width150">Date</th>
							<th>Payee</th>
							<th className="align-center width100">Out</th>
							<th className="align-center width100">In</th>
							<th className="align-right width100">Balance</th>
							<th className="align-center">Clear</th>
						</tr>
						</thead>
						<tbody>
						<tr>
							<td className="checkbox-table align-center">
								<Form.Check type='checkbox' onChange={this.handleSelectAllChecked}/>
							</td>
							<td>
								<FormControl type="date" placeholder="Date" value={this.state.transaction.date} onChange={this.handleChangeDate}/>
							</td>
							<td>
								<FormControl type="text" placeholder="Payee" value={this.state.transaction.payee} onChange={this.handleChangePayee}/>
							</td>
							<td>
								<FormControl type="text" placeholder="Out" value={this.state.transaction.out} onChange={this.handleChangeOut} onClick={this.handleSelect}/>
							</td>
							<td>
								<FormControl type="text" placeholder="In" value={this.state.transaction.in} onChange={this.handleChangeIn} onClick={this.handleSelect}/>
							</td>
							<td className={this.state.clearedBalance < 0 ? 'text-danger align-right' : 'align-right'}>
								{Utils.moneyFormatAmountFromInteger(this.state.clearedBalance, this.state.account.currency)}
							</td>
							<td className="align-right">
								<Button type="submit" onClick={this.handleSubmit}>Create</Button>
							</td>
						</tr>
						{this.state.transactionEntries.map((entry) =>
							this.state.editTransactionId !== entry.transaction.transactionId ?
							<tr key={entry.transaction.transactionId} data-id={entry.transaction.transactionId} onDoubleClick={this.handleDoubleClick} className={AccountContainer.rowClassName(this, entry)}>
								<td className="checkbox-table align-center">
									<Form.Check type='checkbox' checked={this.state.selected[entry.transaction.transactionId]} data-id={entry.transaction.transactionId} onChange={this.handleChangeSelected}/>
								</td>
								<td>{moment(entry.transaction.date).format("YYYY-MM-DD")}</td>
								<td>{entry.transaction.payee}</td>
								<td className="align-right">
									{entry.transaction.amount < 0 && Utils.moneyFormatAmountFromInteger(-entry.transaction.amount, this.state.account.currency)}
									</td>
								<td className="align-right">
									{entry.transaction.amount > 0 && Utils.moneyFormatAmountFromInteger(entry.transaction.amount, this.state.account.currency)}
									</td>
								<td className={entry.balance < 0 ? 'text-danger align-right' : 'align-right'}>
									{Utils.moneyFormatAmountFromInteger(entry.balance, this.state.account.currency)}
								</td>
								<td className="align-center">
									{entry.transaction.clear &&
									<Badge variant="success">clear</Badge>
									}
								</td>
							</tr>
								:
								<tr key={entry.transaction.transactionId}>
									<td/>
									<td>
										<FormControl type="date" placeholder="Date" value={this.state.editTransaction.date} onChange={this.handleEditChangeDate}/>
									</td>
									<td>
										<FormControl type="text" placeholder="Payee" value={this.state.editTransaction.payee} onChange={this.handleEditChangePayee}/>
									</td>
									<td>
										<FormControl type="text" placeholder="Out" value={this.state.editTransaction.out} onChange={this.handleEditChangeOut} onClick={this.handleSelect}/>
									</td>
									<td>
										<FormControl type="text" placeholder="In" value={this.state.editTransaction.in} onChange={this.handleEditChangeIn} onClick={this.handleSelect}/>
									</td>
									<td/>
									<td className="align-right">
										<Button type="submit" onClick={this.handleEditCancel}>Cancel</Button>
										<Button type="submit" onClick={this.handleEditSubmit}>Save</Button>
									</td>
								</tr>
						)}
						</tbody>
					</Table>
				</div>

			</div>
		);
	}

}

export default withRouter(AccountContainer);