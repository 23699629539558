import React from 'react';
import Utils from '../utils/Utils'
import {Link} from 'react-router-dom';

import Table from 'react-bootstrap/Table';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';

class AccountListContainer extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			accounts: [],
			account: {
				name: "",
				currency: "CAD",
				startingBalance: "0"
			},
			loading: false
		};

		this.handleChangeName = this.handleChangeName.bind(this);
		this.handleChangeCurrency = this.handleChangeCurrency.bind(this);
		this.handleChangeStartingBalance = this.handleChangeStartingBalance.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleDelete = this.handleDelete.bind(this);

	}

	componentDidMount() {
		this.getAccounts(this);
	}

	getAccounts(_this) {
		_this.setState({ loading: true }, function () {
			fetch(Utils.serviceUrl("/accounts"))
				.then(function (response) {
					response.json().then(function (data) {
						_this.setState({accounts: data.results}, function () {
							_this.setState({ loading: false });
						});
					});
				});
		});
	}

	handleSubmit(event) {

		let _this = this;
		event.preventDefault();

		let account = this.state.account;
		account.startingBalance = Utils.moneyGetAmountFromDecimal(account.startingBalance, account.currency);

		_this.setState({ loading: true }, function () {
			fetch(Utils.serviceUrl(`/accounts`), {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(account)
			}).then(response => {
				_this.getAccounts(_this);
				_this.setState({
					account: {
						name: "",
						currency: "CAD",
						startingBalance: "0"
					}
				});
			});
		});

	}

	handleDelete(event) {

		let _this = this;
		event.preventDefault();

		let accountId = event.currentTarget.dataset.id;

		_this.setState({ loading: true }, function () {
			fetch(Utils.serviceUrl(`/accounts/${accountId}`), {
				method: 'DELETE'
			}).then(response => {
				_this.getAccounts(_this);
			});

		});

	}

	handleChangeName(event) {
		let account = this.state.account;
		account.name = event.target.value;
		this.setState({
			account: account
		});
	}

	handleChangeCurrency(event) {
		let account = this.state.account;
		account.currency = event.target.value;
		this.setState({
			account: account
		});
	}

	handleChangeStartingBalance(event) {
		let account = this.state.account;
		account.startingBalance = event.target.value;
		this.setState({
			account: account
		});
	}

	render() {
		return (
			<div>

				<h1 className="mb-4 border-bottom">
					Accounts {this.state.loading && <small><span className="glyphicon glyphicon-refresh glyphicon-spin"/></small>}
				</h1>

				<Table striped hover responsive>
					<thead>
					<tr>
						<th>Name</th>
						<th className="align-center">Currency</th>
						<th className="align-center width150">Starting Balance</th>
						<th/>
					</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<FormControl type="text" placeholder="Name" value={this.state.account.name} onChange={this.handleChangeName}/>
							</td>
							<td>
								<FormControl as="select" placeholder="Currency" onChange={this.handleChangeCurrency} custom="true">
									<option value="CAD">CAD</option>
									<option value="USD">USD</option>
								</FormControl>
							</td>
							<td>
								<FormControl type="text" placeholder="Starting Balance" value={this.state.account.startingBalance} onChange={this.handleChangeStartingBalance}/>
							</td>
							<td className="align-right">
								<Button type="submit" onClick={this.handleSubmit} value="Create">Create</Button>
							</td>
						</tr>
						{this.state.accounts.map((account) =>
							<tr key={account.accountId}>
								<td>
									<Link to={`/accounts/${account.accountId}/transactions`}>
										{account.name}
									</Link>
								</td>
								<td>{account.currency}</td>
								<td className="align-right">{Utils.moneyFormatAmountFromInteger(account.startingBalance, account.currency)}</td>
								<td>
									<DropdownButton id={`actions-${account.accountId}`} variant="link" title="Actions" className="no-margin no-padding">
										<Dropdown.Item eventKey="1" data-id={account.accountId} onClick={this.handleDelete}>
											<span className="text-danger">Delete</span>
										</Dropdown.Item>
									</DropdownButton>
								</td>
							</tr>
						)}
					</tbody>
				</Table>

			</div>
		);
	}

}

export default AccountListContainer;
