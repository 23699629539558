import React from 'react';
import { createRoot } from 'react-dom/client';
import {BrowserRouter, Routes, Route} from 'react-router-dom'

import IndexContainer from './containers/IndexContainer';
import AccountListContainer from './containers/AccountListContainer';
import TransactionListContainer from './containers/TransactionListContainer';
import ScheduledTransactionListContainer from './containers/ScheduledTransactionListContainer';
import ScheduleContainer from './containers/ScheduleContainer';
import ReconcileContainer from './containers/ReconcileContainer';

import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';

import './styles/main.css'

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
	<div>
		<Navbar bg="light" expand="lg">
			<Container>
				<Navbar.Brand href="/">
					Stacks
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="mr-auto">
						<Nav.Link href="/accounts">Accounts</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
		<Container className='mt-5'>
			<BrowserRouter>
				<Routes>
					<Route path="/accounts/:accountId/transactions" element={<TransactionListContainer/>}/>
					<Route path="/accounts/:accountId/scheduledTransactions" element={<ScheduledTransactionListContainer/>}/>
					<Route path="/accounts/:accountId/schedule" element={<ScheduleContainer/>}/>
					<Route path="/accounts/:accountId/reconcile" element={<ReconcileContainer/>}/>
					<Route path="/accounts" element={<AccountListContainer/>}/>
					<Route path="/" element={<IndexContainer/>}/>
				</Routes>
			</BrowserRouter>
		</Container>
	</div>
);
