import React from 'react';
import { withRouter } from '../utils/Utils';
import Utils from '../utils/Utils';
import moment from 'moment';
import Table from 'react-bootstrap/Table';
import {Nav,ButtonToolbar,FormControl,Button,FormCheck} from 'react-bootstrap';

class ScheduledTransactionListContainer extends React.Component {

	constructor(props) {
		super(props);

		let accountId = this.props.params.accountId;

		this.state = {
			accountId: accountId,
			account: {},
			scheduledTransactions: [],
			scheduledTransaction: {
				payee: "",
				in: "0",
				out: "0",
				date: moment().format('YYYY-MM-DD'),
				schedule: 'biweekly'
			},
			editScheduledTransactionId: null,
			editScheduledTransaction: { },
			editScheduledTransactionApplyToUncleared: false,
			loading: false,
			selected: { }
		};
		this.handleDeleteSelected = this.handleDeleteSelected.bind(this);
		this.handleChangePayee = this.handleChangePayee.bind(this);
		this.handleChangeIn = this.handleChangeIn.bind(this);
		this.handleChangeOut = this.handleChangeOut.bind(this);
		this.handleChangeDate = this.handleChangeDate.bind(this);
		this.handleChangeSchedule = this.handleChangeSchedule.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);

		this.handleDoubleClick = this.handleDoubleClick.bind(this);
		this.handleEditChangePayee = this.handleEditChangePayee.bind(this);
		this.handleEditChangeIn = this.handleEditChangeIn.bind(this);
		this.handleEditChangeOut = this.handleEditChangeOut.bind(this);
		this.handleEditChangeDate = this.handleEditChangeDate.bind(this);
		this.handleEditChangeSchedule = this.handleEditChangeSchedule.bind(this);
		this.handleEditSubmit = this.handleEditSubmit.bind(this);
		this.handleEditCancel = this.handleEditCancel.bind(this);

		this.handleChangeSelected = this.handleChangeSelected.bind(this);
		this.handleSelect = this.handleSelect.bind(this);

		this.handleApplyToUnclearedChanged = this.handleApplyToUnclearedChanged.bind(this);

	}

	componentDidMount() {

		let _this = this;

		_this.setState({ loading: true });

		fetch(Utils.serviceUrl(`/accounts/${_this.state.accountId}`))
			.then(function (response) {
				response.json().then(function (data) {
					_this.setState({account: data});
				});
			});

		_this.getScheduledTransactions(_this);

	}

	getScheduledTransactions(_this) {

		_this.setState({ loading: true }, function () {
			fetch(Utils.serviceUrl(`/accounts/${_this.state.accountId}/scheduledTransactions`))
				.then(function (response) {
					console.log(response);
					response.json().then(function (data) {
						_this.setState({scheduledTransactions: data.results}, function () {
							_this.setState({ loading: false });
						});
					});
				});
		});
	}

	handleSubmit(event) {

		let _this = this;
		event.preventDefault();

		let scheduledTransaction = this.state.scheduledTransaction;
		let input = scheduledTransaction.in && scheduledTransaction.in !== "0" ? scheduledTransaction.in : scheduledTransaction.out;
		scheduledTransaction.amount = Utils.moneyGetAmountFromDecimal(input, this.state.account.currency);
		if(scheduledTransaction.out && scheduledTransaction.out !== "0") scheduledTransaction.amount = -scheduledTransaction.amount;
		delete scheduledTransaction.in;
		delete scheduledTransaction.out;

		_this.setState({ loading: true }, function () {
			fetch(Utils.serviceUrl(`/accounts/${_this.state.accountId}/scheduledTransactions`), {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(scheduledTransaction)
			}).then(response => {
				_this.getScheduledTransactions(_this);
				_this.setState({
					scheduledTransaction: {
						payee: "",
						in: "0",
						out: "0",
						date: moment().format('YYYY-MM-DD'),
						schedule: 'biweekly'
					}
				});
			});
		});

	}

	handleChangeSelected(event) {

		let scheduledTransactionId = event.currentTarget.dataset.id;
		let selected = this.state.selected;

		selected[scheduledTransactionId] = event.target.checked;

		console.log(selected);

		this.setState({
			selected: selected
		});
	}

	handleChangePayee(event) {
		let scheduledTransaction = this.state.scheduledTransaction;
		scheduledTransaction.payee = event.target.value;
		this.setState({
			scheduledTransaction: scheduledTransaction
		});
	}

	handleChangeIn(event) {
		let scheduledTransaction = this.state.scheduledTransaction;
		scheduledTransaction.in = event.target.value.trim().replace(/[^0-9.,]/g, '')
		scheduledTransaction.out = "0";
		this.setState({
			scheduledTransaction: scheduledTransaction
		});
	}

	handleChangeOut(event) {
		let scheduledTransaction = this.state.scheduledTransaction;
		scheduledTransaction.in = "0";
		scheduledTransaction.out = event.target.value.trim().replace(/[^0-9.,]/g, '')
		this.setState({
			scheduledTransaction: scheduledTransaction
		});
	}

	handleChangeDate(event) {
		let scheduledTransaction = this.state.scheduledTransaction;
		scheduledTransaction.date = event.target.value;
		this.setState({
			scheduledTransaction: scheduledTransaction
		});
	}

	handleChangeSchedule(event) {
		let scheduledTransaction = this.state.scheduledTransaction;
		scheduledTransaction.schedule = event.target.value;
		this.setState({
			scheduledTransaction: scheduledTransaction
		});
	}

	handleDeleteSelected(event) {

		let _this = this;
		event.preventDefault();

		let scheduledTransactionIds = Object.keys(this.state.selected).filter(key => this.state.selected[key]);

		console.dir(this.state);

		this.setState({ loading: true });

		fetch(Utils.serviceUrl(`/accounts/${_this.state.accountId}/batchScheduledTransactions`), {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				scheduledTransactionIds: scheduledTransactionIds
			})
		})
			.then(function (response) {
				_this.getScheduledTransactions(_this);
			});

	}

	handleDoubleClick(event) {
		event.preventDefault();
		let scheduledTransactionId = event.currentTarget.dataset.id;
		let scheduledTransaction = this.state.scheduledTransactions.find(t => t.scheduledTransactionId === scheduledTransactionId);
		this.setState({
			editScheduledTransactionId: scheduledTransactionId,
			editScheduledTransaction: {
				payee: scheduledTransaction.payee,
				in: scheduledTransaction.amount >= 0 ? Utils.moneyFormatAmountFromInteger(scheduledTransaction.amount, this.state.account.currency) : "0",
				out: scheduledTransaction.amount < 0 ? Utils.moneyFormatAmountFromInteger(-scheduledTransaction.amount, this.state.account.currency) : "0",
				date: scheduledTransaction.date,
				schedule: scheduledTransaction.schedule
			}
		});
	}

	static rowClassName(context, scheduledTransaction) {
		var classes = '';
		if(typeof context.state !== 'undefined' &&
			typeof context.state.selected !== 'undefined' &&
			scheduledTransaction.scheduledTransactionId in context.state.selected &&
			context.state.selected[scheduledTransaction.scheduledTransactionId]) {
			classes += ' info';
		}
		return classes;
	}

	countSelected() {
		return Object.keys(this.state.selected).map(key => this.state.selected[key]).filter(v => v).length;
	}

	handleEditChangePayee(event) {
		let scheduledTransaction = this.state.editScheduledTransaction;
		scheduledTransaction.payee = event.target.value;
		this.setState({
			editScheduledTransaction: scheduledTransaction
		});
	}

	handleEditChangeIn(event) {
		let scheduledTransaction = this.state.editScheduledTransaction;
		scheduledTransaction.in = event.target.value.trim().replace(/[^0-9.,]/g, '')
		scheduledTransaction.out = "0";
		this.setState({
			editScheduledTransaction: scheduledTransaction
		});
	}

	handleEditChangeOut(event) {
		let scheduledTransaction = this.state.editScheduledTransaction;
		scheduledTransaction.in = "0";
		scheduledTransaction.out = event.target.value.trim().replace(/[^0-9.,]/g, '')
		this.setState({
			editScheduledTransaction: scheduledTransaction
		});
	}

	handleEditChangeDate(event) {
		let scheduledTransaction = this.state.editScheduledTransaction;
		scheduledTransaction.date = event.target.value;
		this.setState({
			editScheduledTransaction: scheduledTransaction
		});
	}

	handleEditChangeSchedule(event) {
		let scheduledTransaction = this.state.editScheduledTransaction;
		scheduledTransaction.schedule = event.target.value;
		this.setState({
			editScheduledTransaction: scheduledTransaction
		});
	}

	handleEditCancel(event) {
		event.preventDefault();
		this.setState({
			editScheduledTransactionId: null,
			editScheduledTransaction: { }
		});
	}

	handleSelect(event) {
		event.preventDefault();
		event.target.select();
	}

	handleEditSubmit(event) {

		event.preventDefault();

		let scheduledTransaction = this.state.editScheduledTransaction;

		let input = scheduledTransaction.in && scheduledTransaction.in !== "0" ? scheduledTransaction.in : scheduledTransaction.out;
		scheduledTransaction.amount = Utils.moneyGetAmountFromDecimal(input, this.state.account.currency);

		if(scheduledTransaction.out && scheduledTransaction.out !== "0") scheduledTransaction.amount = -scheduledTransaction.amount;

		delete scheduledTransaction.in;
		delete scheduledTransaction.out;

		scheduledTransaction.applyToUncleared = this.state.editScheduledTransactionApplyToUncleared;

		let scheduledTransactionId = this.state.editScheduledTransactionId;

		let _this = this;

		this.setState({ loading: true }, function () {
			this.setState({loading: true}, function () {
				fetch(Utils.serviceUrl(`/accounts/${_this.state.accountId}/scheduledTransactions/${scheduledTransactionId}`), {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(scheduledTransaction)
				}).then(response => {
					_this.getScheduledTransactions(_this);
					this.setState({
						editScheduledTransactionId: null,
						editScheduledTransaction: {}
					});
				});
			});
		});

	}

	handleApplyToUnclearedChanged(event) {

		let checked = event.target.checked;

		this.setState({
			editScheduledTransactionApplyToUncleared: checked
		});
	}

	render() {
		return (
			<div>

				<div className="mb-4 border-bottom">
					<div className="actions float-right">
						<ButtonToolbar>
							{this.countSelected() > 0 &&
								<Button type="submit" variant="danger" onClick={this.handleDeleteSelected}>Delete</Button>
							}
						</ButtonToolbar>
					</div>
					<h1>
						{this.state.account.name} {this.state.loading && <small><span className="glyphicon glyphicon-refresh glyphicon-spin"/></small>}
					</h1>
				</div>

				<Nav variant="tabs" activeKey="2">
					<Nav.Item>
						<Nav.Link eventKey="1" href={`/accounts/${this.state.accountId}/transactions`}>Transactions</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link eventKey="2" href={`/accounts/${this.state.accountId}/scheduledTransactions`}>Schedule</Nav.Link>
					</Nav.Item>
					<Nav.Item >
						<Nav.Link eventKey="3" href={`/accounts/${this.state.accountId}/reconcile`}>Reconcile</Nav.Link>
					</Nav.Item>
				</Nav>

				<Table striped hover responsive>
					<thead>
					<tr>
						<th className="width40"/>
						<th className="width150">Start Date</th>
						<th>Payee</th>
						<th className="align-center width100">Out</th>
						<th className="align-center width100">In</th>
						<th className="align-center">Schedule</th>
						<th/>
					</tr>
					</thead>
					<tbody>
						<tr>
							<td/>
							<td>
								<FormControl type="date" placeholder="Date" value={this.state.scheduledTransaction.date} onChange={this.handleChangeDate}/>
							</td>
							<td>
								<FormControl type="text" placeholder="Payee" value={this.state.scheduledTransaction.payee} onChange={this.handleChangePayee}/>
							</td>
							<td>
								<FormControl type="text" placeholder="Out" value={this.state.scheduledTransaction.out} onChange={this.handleChangeOut} onClick={this.handleSelect}/>
							</td>
							<td>
								<FormControl type="text" placeholder="In" value={this.state.scheduledTransaction.in} onChange={this.handleChangeIn} onClick={this.handleSelect}/>
							</td>
							<td>
								<FormControl as="select" value={this.state.scheduledTransaction.schedule} placeholder="Schedule" onChange={this.handleChangeSchedule} custom="true">
									<option value="weekly">Weekly</option>
									<option value="biweekly">Biweekly</option>
									<option value="monthly">Monthly</option>
									<option value="bimonthly">Bimonthly</option>
								</FormControl>
							</td>
							<td/>
							<td className="align-right">
								<Button type="submit" onClick={this.handleSubmit}>Create</Button>
							</td>
						</tr>
						{this.state.scheduledTransactions.map((scheduledTransaction) =>

							this.state.editScheduledTransactionId !== scheduledTransaction.scheduledTransactionId ?
								<tr key={scheduledTransaction.scheduledTransactionId} data-id={scheduledTransaction.scheduledTransactionId} onDoubleClick={this.handleDoubleClick} className={ScheduledTransactionListContainer.rowClassName(this, scheduledTransaction)}>
									<td className="checkbox-table align-center">
										<FormCheck type='checkbox' checked={this.state.selected[scheduledTransaction.scheduledTransactionId]} data-id={scheduledTransaction.scheduledTransactionId} onChange={this.handleChangeSelected}/>
									</td>
									<td>{moment(scheduledTransaction.date).format("YYYY-MM-DD")}</td>
									<td>{scheduledTransaction.payee}</td>
									<td className="align-right">
										{scheduledTransaction.amount < 0 && Utils.moneyFormatAmountFromInteger(-scheduledTransaction.amount, this.state.account.currency)}
									</td>
									<td className="align-right">
										{scheduledTransaction.amount > 0 && Utils.moneyFormatAmountFromInteger(scheduledTransaction.amount, this.state.account.currency)}
									</td>
									<td className="align-center width100">{scheduledTransaction.schedule}</td>
									<td/>
								</tr>
							:
								<tr key={scheduledTransaction.scheduledTransactionId} >
									<td/>
									<td>
										<FormControl type="date" placeholder="Date" value={this.state.editScheduledTransaction.date} onChange={this.handleEditChangeDate}/>
									</td>
									<td>
										<FormControl type="text" placeholder="Payee" value={this.state.editScheduledTransaction.payee} onChange={this.handleEditChangePayee}/>
									</td>
									<td>
										<FormControl type="text" placeholder="Out" value={this.state.editScheduledTransaction.out} onChange={this.handleEditChangeOut} onClick={this.handleSelect}/>
									</td>
									<td>
										<FormControl type="text" placeholder="In" value={this.state.editScheduledTransaction.in} onChange={this.handleEditChangeIn} onClick={this.handleSelect}/>
									</td>
									<td>
										<FormControl as="select" value={this.state.editScheduledTransaction.schedule} placeholder="Schedule" onChange={this.handleEditChangeSchedule} custom="true">
											<option value="weekly">Weekly</option>
											<option value="biweekly">Biweekly</option>
											<option value="monthly">Monthly</option>
											<option value="bimonthly">Bimonthly</option>
										</FormControl>
									</td>
									<td className="checkbox-table align-center">
										apply to uncleared?
										<FormCheck type='checkbox' checked={this.state.editScheduledTransactionApplyToUncleared} data-id={scheduledTransaction.scheduledTransactionId} onChange={this.handleApplyToUnclearedChanged}/>
									</td>
									<td className="align-right">
										<Button type="submit" onClick={this.handleEditCancel}>Cancel</Button>
										<Button type="submit" onClick={this.handleEditSubmit}>Save</Button>
									</td>
								</tr>

						)}
					</tbody>
				</Table>

			</div>
		);
	}

}

export default withRouter(ScheduledTransactionListContainer);
