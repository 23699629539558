import React from 'react';
import Utils from '../utils/Utils'

class IndexContainer extends React.Component {

	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		fetch(Utils.serviceUrl("/accounts"))
			.then(function (response) {
				response.json().then(function (data) {
					var hasDefault = false;
					data.results.forEach(account => {
						if(account.defaultAccount) {
							window.location = `/accounts/${account.accountId}/transactions`;
							hasDefault = true;
						}
					});
					if(!hasDefault) {
						window.location = `/accounts`;
					}
				});
			});
	}

	render() {
		return (
			<div/>
		);
	}

}

export default IndexContainer;
