import React from 'react';
import { withRouter } from '../utils/Utils';
import moment from 'moment';
import Utils from '../utils/Utils';

import Table from 'react-bootstrap/Table';
import {Button,ButtonToolbar,FormGroup,FormControl,FormLabel,FormCheck,Nav,NavItem} from 'react-bootstrap';

class ReconcileContainer extends React.Component {

	constructor(props) {
		super(props);

		let accountId = this.props.params.accountId;

		this.state = {
			accountId: accountId,
			account: {
				currency: "CAD"
			},
			transactionEntries: [],
			cleared: {},
			date: moment().format('YYYY-MM-DD'),
			balance: "0",
			clearedBalance: 0,
			unclearedBalance: 0,
			diff: 0,
			transaction: {
				payee: "",
				in: "0",
				out: "0",
				date: moment().format('YYYY-MM-DD')
			},
			editTransactionId: null,
			editTransaction: { },
			loading: false,
			selected: {}
		};
		this.handleSubmitReconcile = this.handleSubmitReconcile.bind(this);
		this.handleChangeDate = this.handleChangeDate.bind(this);
		this.handleChangeBalance = this.handleChangeBalance.bind(this);
		this.handleClickSelect = this.handleClickSelect.bind(this);
		this.handleChangeClear = this.handleChangeClear.bind(this);

		this.handleTransactionChangePayee = this.handleTransactionChangePayee.bind(this);
		this.handleTransactionChangeIn = this.handleTransactionChangeIn.bind(this);
		this.handleTransactionChangeOut = this.handleTransactionChangeOut.bind(this);
		this.handleTransactionChangeDate = this.handleTransactionChangeDate.bind(this);
		this.handleTransactionSubmit = this.handleTransactionSubmit.bind(this);

		this.handleDoubleClick = this.handleDoubleClick.bind(this);
		this.handleEditTransactionChangePayee = this.handleEditTransactionChangePayee.bind(this);
		this.handleEditTransactionChangeIn = this.handleEditTransactionChangeIn.bind(this);
		this.handleEditTransactionChangeOut = this.handleEditTransactionChangeOut.bind(this);
		this.handleEditTransactionChangeDate = this.handleEditTransactionChangeDate.bind(this);
		this.handleEditTransactionSubmit = this.handleEditTransactionSubmit.bind(this);
		this.handleEditTransactionCancel = this.handleEditTransactionCancel.bind(this);

		this.handleChangeSelected = this.handleChangeSelected.bind(this);
		this.handleDeleteSelected = this.handleDeleteSelected.bind(this);

		this.handleClearAllChecked = this.handleClearAllChecked.bind(this);
	}

	componentDidMount() {

		let _this = this;

		_this.setState({ loading: true });

		fetch(Utils.serviceUrl(`/accounts/${_this.state.accountId}`))
			.then(function (response) {
				response.json().then(function (data) {
					_this.setState({account: data});
				});
			});

		_this.getTransactions(_this);

	}

	handleSubmitReconcile(event) {

		event.preventDefault();

		let _this = this;

		let request = {
			transactions: {}
		};

		this.state.transactionEntries
			.forEach(entry => {
				request.transactions[entry.transaction.transactionId] = {
					clear: this.state.cleared[entry.transaction.transactionId]
				};
			});

		_this.setState({ loading: true });

		fetch(Utils.serviceUrl(`/accounts/${_this.state.accountId}/batchTransactions`), {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(request)
		}).then(response => window.location = `/accounts/${_this.state.accountId}/transactions`);

	}

	handleChangeDate(event) {

		let _this = this;

		this.setState({
			date: event.target.value
		}, function() {
			_this.getTransactions(_this);
		});
	}

	getTransactions(_this) {

		_this.setState({ loading: true });

		fetch(Utils.serviceUrl(`/accounts/${_this.state.accountId}/transactions?clear=false&end=${_this.state.date}`))
			.then(function (response) {
				response.json().then(function (data) {
					let diff = data.clearedBalance - Utils.moneyGetAmountFromDecimal(_this.state.balance, _this.state.account.currency);
					let cleared = {};
					let selected = {};
					let transactionIds = data.results.map(entry => entry.transaction.transactionId);
					data.results.forEach(entry => {
						cleared[entry.transaction.transactionId] = entry.transaction.clear;
					});
					transactionIds.forEach(transactionId => {
						if (!(transactionId in selected)) {
							selected[transactionId] = false
						}
					});
					Object.keys(selected).forEach(transactionId => {
						if(!(transactionIds.includes(transactionId))) {
							delete selected[transactionId];
						}
					});
					_this.setState({
						transactionEntries: data.results,
						clearedBalance: data.clearedBalance,
						unclearedBalance: data.unclearedBalance,
						diff: diff,
						cleared: cleared,
						selected: selected
					});
					_this.setState({ loading: false });
				});
			});

	}

	updateTransactions(_this) {

		_this.setState({ loading: true });

		fetch(Utils.serviceUrl(`/accounts/${_this.state.accountId}/transactions?clear=false&end=${_this.state.date}`))
			.then(function (response) {
				response.json().then(function (data) {

					let cleared = _this.state.cleared;
					let clearedBalance = data.clearedBalance;
					let unclearedBalance = data.unclearedBalance;

					let selected = _this.state.selected;
					let transactionIds = data.results.map(entry => entry.transaction.transactionId);

					data.results.forEach(entry => {
						if (!(entry.transaction.transactionId in cleared)) {
							cleared[entry.transaction.transactionId] = entry.transaction.clear;
						}
						if(cleared[entry.transaction.transactionId]) {
							clearedBalance += entry.transaction.amount;
							unclearedBalance -= entry.transaction.amount;
						}
					});

					let diff = clearedBalance - Utils.moneyGetAmountFromDecimal(_this.state.balance, _this.state.account.currency);

					transactionIds.forEach(transactionId => {
						if (!(transactionId in selected)) {
							selected[transactionId] = false
						}
					});
					Object.keys(selected).forEach(transactionId => {
						if(!(transactionIds.includes(transactionId))) {
							delete selected[transactionId];
						}
					});

					_this.setState({
						transactionEntries: data.results,
						clearedBalance: clearedBalance,
						unclearedBalance: unclearedBalance,
						diff: diff,
						cleared: cleared,
						selected: selected
					});

					_this.setState({ loading: false });

				});
			});

	}

	handleChangeBalance(event) {
		event.preventDefault();
		let balance = event.target.value.trim().replace(/[^0-9-.,]/g, '')
		let diff = this.state.clearedBalance - Utils.moneyGetAmountFromDecimal(balance, this.state.account.currency);
		this.setState({
			balance: balance,
			diff: diff
		});
	}

	handleClickSelect(event) {
		event.preventDefault();
		event.target.select();
	}

	handleChangeClear(event) {
		let transactionId = event.currentTarget.dataset.id;
		let clear = event.target.checked;
		let _this = this;

		console.log(_this.state.cleared);
		_this.changeClear([transactionId], clear);
		console.log(_this.state.cleared);

	}

	changeClear(transactionIds, clear) {

		let clearedBalance = this.state.clearedBalance;
		let unclearedBalance = this.state.unclearedBalance;
		let transactionEntries = this.state.transactionEntries;
		let cleared = this.state.cleared;

		transactionIds.forEach(transactionId => {

			let index = transactionEntries.findIndex(t => t.transaction.transactionId === transactionId);

			if(!(transactionId in cleared) || cleared[transactionId] !== clear) {

				cleared[transactionId] = clear;

				if(clear) {
					clearedBalance += transactionEntries[index].transaction.amount;
					unclearedBalance -= transactionEntries[index].transaction.amount;
				} else {
					clearedBalance -= transactionEntries[index].transaction.amount;
					unclearedBalance += transactionEntries[index].transaction.amount;
				}

			}

		});

		let diff = (clearedBalance - Utils.moneyGetAmountFromDecimal(this.state.balance, this.state.account.currency));

		this.setState({
			cleared: cleared,
			clearedBalance: clearedBalance,
			unclearedBalance: unclearedBalance,
			diff: diff
		});

	}

	handleTransactionSubmit(event) {

		let _this = this;
		event.preventDefault();

		let transaction = this.state.transaction;
		let input = transaction.in && transaction.in !== "0" ? transaction.in : transaction.out;
		transaction.amount = Utils.moneyGetAmountFromDecimal(input, this.state.account.currency);

		if(transaction.out && transaction.out !== "0") transaction.amount = -transaction.amount;
		delete transaction.in;
		delete transaction.out;

		_this.setState({ loading: true });

		fetch(Utils.serviceUrl(`/accounts/${_this.state.accountId}/transactions`), {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(transaction)
		}).then(response => {
			_this.updateTransactions(_this);
			_this.setState({
				transaction: {
					payee: "",
					in: "0",
					out: "0",
					date: moment().format('YYYY-MM-DD')
				}
			});
		});

	}

	handleTransactionChangePayee(event) {
		let transaction = this.state.transaction;
		transaction.payee = event.target.value;
		this.setState({
			transaction: transaction
		});
	}

	handleTransactionChangeIn(event) {
		let transaction = this.state.transaction;
		transaction.in = event.target.value.trim().replace(/[^0-9.,]/g, '')
		transaction.out = "0";
		this.setState({
			transaction: transaction
		});
	}

	handleTransactionChangeOut(event) {
		let transaction = this.state.transaction;
		transaction.in = "0";
		transaction.out = event.target.value.trim().replace(/[^0-9.,]/g, '')
		this.setState({
			transaction: transaction
		});
	}

	handleTransactionChangeDate(event) {
		let transaction = this.state.transaction;
		transaction.date = event.target.value;
		this.setState({
			transaction: transaction
		});
	}

	handleDoubleClick(event) {
		event.preventDefault();
		let transactionId = event.currentTarget.dataset.id;
		let transaction = this.state.transactionEntries.map(t => t.transaction).find(t => t.transactionId === transactionId);
		this.setState({
			editTransactionId: transactionId,
			editTransaction: {
				payee: transaction.payee,
				in: transaction.amount >= 0 ? Utils.moneyFormatAmountFromInteger(transaction.amount, this.state.account.currency) : "0",
				out: transaction.amount < 0 ? Utils.moneyFormatAmountFromInteger(-transaction.amount, this.state.account.currency) : "0",
				date: transaction.date
			}
		}, function () {
			console.dir(this.state.editTransaction);
		});
	}

	handleEditTransactionChangeDate(event) {
		let editTransaction = this.state.editTransaction;
		editTransaction.date = event.target.value;
		this.setState({
			editTransaction: editTransaction
		});
	}

	handleEditTransactionChangePayee(event) {
		let editTransaction = this.state.editTransaction;
		editTransaction.payee = event.target.value;
		this.setState({
			editTransaction: editTransaction
		});
	}

	handleEditTransactionChangeOut(event) {
		let editTransaction = this.state.editTransaction;
		editTransaction.out = event.target.value.trim().replace(/[^0-9.,]/g, '')
		editTransaction.in = "0";
		this.setState({
			editTransaction: editTransaction
		});
	}

	handleEditTransactionChangeIn(event) {
		let editTransaction = this.state.editTransaction;
		editTransaction.out = "0";
		editTransaction.in = event.target.value.trim().replace(/[^0-9.,]/g, '')
		this.setState({
			editTransaction: editTransaction
		});
	}

	handleEditTransactionCancel(event) {
		event.preventDefault();
		this.setState({
			editTransactionId: null,
			editTransaction: { }
		});
	}

	handleEditTransactionSubmit(event) {

		let _this = this;
		event.preventDefault();

		let transaction = this.state.editTransaction;
		let input = transaction.in && transaction.in !== "0" ? transaction.in : transaction.out;
		transaction.amount = Utils.moneyGetAmountFromDecimal(input, this.state.account.currency);

		if(transaction.out && transaction.out !== "0") transaction.amount = -transaction.amount;
		delete transaction.in;
		delete transaction.out;

		let transactionId = this.state.editTransactionId;

		_this.setState({ loading: true });

		fetch(Utils.serviceUrl(`/accounts/${_this.state.accountId}/transactions/${transactionId}`), {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(transaction)
		}).then(response => {
			_this.updateTransactions(_this);
			this.setState({
				editTransactionId: null,
				editTransaction: { }
			});
		});

	}

	handleChangeSelected(event) {

		let transactionId = event.currentTarget.dataset.id;
		let selected = this.state.selected;

		selected[transactionId] = event.target.checked;

		this.setState({
			selected: selected
		});
	}

	countSelected() {
		return Object.keys(this.state.selected).map(key => this.state.selected[key]).filter(v => v).length;
	}

	handleDeleteSelected(event) {

		let _this = this;
		event.preventDefault();

		let transactionIds = Object.keys(this.state.selected).filter(key => this.state.selected[key]);

		console.dir(this.state);

		this.setState({ loading: true });

		fetch(Utils.serviceUrl(`/accounts/${_this.state.accountId}/batchTransactions`), {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				transactionIds: transactionIds
			})
		})
			.then(function (response) {
				_this.updateTransactions(_this);
			});

	}

	handleClearAllChecked(event) {
		let _this = this;
		let clear = event.target.checked;
		let transactionIds = _this.state.transactionEntries.map(t => t.transaction.transactionId);
		console.log(_this.state.cleared);
		_this.changeClear(transactionIds, clear);
		console.log(_this.state.cleared);
	}

	static rowClassName(context, entry) {
		var classes = '';
		if(typeof context.state !== 'undefined' &&
			typeof context.state.selected !== 'undefined' &&
			entry.transaction.transactionId in context.state.selected &&
			context.state.selected[entry.transaction.transactionId]) {
			classes += ' info';
		} else if(entry.balance < 0) {
			classes += ' danger';
		}
		return classes;
	}

	render() {
		return (
			<div>

				<div className="mb-4 border-bottom">
					<div className="actions float-right">
						<ButtonToolbar>
							{this.countSelected() > 0 &&
								<Button type="submit" variant="danger" onClick={this.handleDeleteSelected}>Delete</Button>
							}
							<Button href={`/accounts/${this.state.accountId}/transactions`}>Cancel</Button>
							<Button type="submit" variant={this.state.diff === 0 ? 'success' : 'danger'} disabled={this.state.diff !== 0} onClick={this.handleSubmitReconcile}>Reconcile</Button>
						</ButtonToolbar>
					</div>
					<h1>
						{this.state.account.name} {this.state.loading && <small><span className="glyphicon glyphicon-refresh glyphicon-spin"/></small>}
					</h1>
				</div>

				<Nav variant="tabs" activeKey="3">
					<Nav.Item>
						<Nav.Link eventKey="1" href={`/accounts/${this.state.accountId}/transactions`}>Transactions</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link eventKey="2" href={`/accounts/${this.state.accountId}/scheduledTransactions`}>Schedule</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link eventKey="3" href={`/accounts/${this.state.accountId}/reconcile`}>Reconcile</Nav.Link>
					</Nav.Item>
				</Nav>

				<form>
					<FormGroup>

						<FormGroup>
							<FormLabel>Date</FormLabel>
							<FormControl type="date" placeholder="Date" value={this.state.date} onChange={this.handleChangeDate}/>
						</FormGroup>

						<FormGroup>
							<FormLabel>Statement Balance</FormLabel>
							<FormControl type="text" placeholder="Balance" value={this.state.balance} onChange={this.handleChangeBalance} onClick={this.handleClickSelect}/>
						</FormGroup>

					</FormGroup>

				</form>

				<table className="vertical-margin">
					<tbody>
						<tr>
							<th>Cleared Balance</th>
							<td/>
							<td className="align-right">
								{Utils.moneyFormatAmountFromInteger(this.state.clearedBalance, this.state.account.currency)}
							</td>
						</tr>
						<tr>
							<th>Statement Balance</th>
							<td className="align-center vertical-padding">-</td>
							<td className="align-right border-bottom">{Utils.moneyFormatAmountFromDecimal(this.state.balance, this.state.account.currency)}</td>
						</tr>
						<tr>
							<th>Difference</th>
							<td/>
							<td className={this.state.diff === 0 ? 'align-right text-success' : 'align-right text-danger'}>
								{Utils.moneyFormatAmountFromInteger(this.state.diff, this.state.account.currency)}
							</td>
						</tr>
					</tbody>
				</table>

				<div>
					<Table striped hover responsive>
						<thead>
						<tr>
							<th className="width40"/>
							<th className="width150">Date</th>
							<th>Payee</th>
							<th className="align-center width100">Out</th>
							<th className="align-center width100">In</th>
							<th className="align-right width100">Balance</th>
							<th className="align-center">Clear</th>
							<th className="width40"/>
						</tr>
						</thead>
						<tbody>
							<tr>
								<td/>
								<td>
									<FormControl type="date" placeholder="Date" value={this.state.transaction.date} onChange={this.handleTransactionChangeDate}/>
								</td>
								<td>
									<FormControl type="text" placeholder="Payee" value={this.state.transaction.payee} onChange={this.handleTransactionChangePayee}/>
								</td>
								<td className="align-right width100">
									<FormControl type="text" placeholder="Out" value={this.state.transaction.out} onChange={this.handleTransactionChangeOut} onClick={this.handleClickSelect}/>
								</td>
								<td className="align-right width100">
									<FormControl type="text" placeholder="In" value={this.state.transaction.in} onChange={this.handleTransactionChangeIn} onClick={this.handleClickSelect}/>
								</td>
								<td/>
								<td className="checkbox-table align-center">
									<FormCheck type='checkbox' onChange={this.handleClearAllChecked}/>
								</td>
								<td className="align-right">
									<Button type="submit" onClick={this.handleTransactionSubmit}>Create</Button>
								</td>
							</tr>
							{this.state.transactionEntries.map((entry) =>
								this.state.editTransactionId !== entry.transaction.transactionId ?
								<tr key={entry.transaction.transactionId} data-id={entry.transaction.transactionId} onDoubleClick={this.handleDoubleClick} className={ReconcileContainer.rowClassName(this, entry)}>
									<td className="checkbox-table align-center">
										<FormCheck type='checkbox' checked={this.state.selected[entry.transaction.transactionId]} data-id={entry.transaction.transactionId} onChange={this.handleChangeSelected}/>
									</td>
									<td>{moment(entry.transaction.date).format("YYYY-MM-DD")}</td>
									<td>{entry.transaction.payee}</td>
									<td className="align-right">
										{entry.transaction.amount < 0 && Utils.moneyFormatAmountFromInteger(-entry.transaction.amount, this.state.account.currency)}
									</td>
									<td className="align-right">
										{entry.transaction.amount > 0 && Utils.moneyFormatAmountFromInteger(entry.transaction.amount, this.state.account.currency)}
									</td>
									<td className={entry.balance < 0 ? 'text-danger align-right' : 'align-right'}>
										{Utils.moneyFormatAmountFromInteger(entry.balance, this.state.account.currency)}
									</td>
									<td className="checkbox-table align-center">
										<FormCheck type='checkbox' checked={this.state.cleared[entry.transaction.transactionId]} data-id={entry.transaction.transactionId} onChange={this.handleChangeClear}/>
									</td>
									<td/>
								</tr>
									:
									<tr key={entry.transaction.transactionId}>
										<td/>
										<td>
											<FormControl type="date" placeholder="Date" value={this.state.editTransaction.date} onChange={this.handleEditTransactionChangeDate}/>
										</td>
										<td>
											<FormControl type="text" placeholder="Payee" value={this.state.editTransaction.payee} onChange={this.handleEditTransactionChangePayee}/>
										</td>
										<td>
											<FormControl type="text" placeholder="Out" value={this.state.editTransaction.out} onChange={this.handleEditTransactionChangeOut} onClick={this.handleClickSelect}/>
										</td>
										<td>
											<FormControl type="text" placeholder="In" value={this.state.editTransaction.in} onChange={this.handleEditTransactionChangeIn} onClick={this.handleClickSelect}/>
										</td>
										<td/>
										<td>
											<ButtonToolbar>
												<Button type="submit" onClick={this.handleEditTransactionCancel}>Cancel</Button>
												<Button type="submit" onClick={this.handleEditTransactionSubmit}>Save</Button>
											</ButtonToolbar>
										</td>
										<td/>
									</tr>
							)}
						</tbody>
					</Table>
				</div>

			</div>
		);
	}

}

export default withRouter(ReconcileContainer);
