import Money from 'js-money';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import currencies from 'js-money/lib/currency'
import { useParams } from 'react-router-dom';

function Utils () {

}

Utils.moneyGetAmountFromDecimal = function (amount, currency) {
	if(amount == null || currency == null) {
		return 0;
	}
	amount = Utils.removeCommas(amount);
	return Money.fromDecimal(amount, currency, Math.round).amount;
};

Utils.removeCommas = function (str) {
	return str.replace(/,/g, '');
};

Utils.moneyFormatAmountFromDecimal = function (amount, currency) {
	if(amount == null || currency == null) {
		return '';
	}
	amount = Utils.removeCommas(amount);
	let money = Money.fromDecimal(amount, currency, Math.round);
	return Utils.moneyToString(money);
};

Utils.moneyFormatAmountFromDecimalNoComma = function (amount, currency) {
	return Utils.moneyFormatAmountFromDecimal(amount, currency).replace(',', '');
};

Utils.moneyFormatAmountFromInteger = function (amount, currency) {
	if(amount == null || currency == null) {
		return '';
	}
	let money = Money.fromInteger({amount: amount, currency: currency});
	return Utils.moneyToString(money);
};

Utils.moneyFormatAmountFromIntegerNoComma = function (amount, currency) {
	return Utils.moneyFormatAmountFromInteger(amount, currency).replace(',', '');
};

Utils.moneyToString = function (money) {
	return money.toDecimal().toLocaleString('en-US', { minimumFractionDigits: currencies[money.currency].decimal_digits })
};

Utils.moneyToStringNoComma = function (money) {
	return money.toDecimal().toLocaleString('en-US', { minimumFractionDigits: currencies[money.currency].decimal_digits }).replace(',', '')
};

Utils.serviceUrl = function (path) {
	let env = runtimeEnv();
	if (typeof env.REACT_APP_SERVICE_SCHEME !== 'undefined') {
		return `${env.REACT_APP_SERVICE_SCHEME}://${env.REACT_APP_SERVICE_URL}:${env.REACT_APP_SERVICE_PORT}${path}`;
	}
	return `http://localhost:9000${path}`;
};

export const withRouter = WrappedComponent => props => {
	const params = useParams();
	return (
		<WrappedComponent
			{...props}
			params={params}
		/>
	);
};

export default Utils;
