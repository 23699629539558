import React from 'react';
import { withRouter } from '../utils/Utils';
import Table from 'react-bootstrap/Table';
import {Button,ButtonToolbar,FormCheck} from 'react-bootstrap';
import moment from 'moment';
import Utils from '../utils/Utils';

class ScheduleContainer extends React.Component {

	constructor(props) {
		super(props);

		let accountId = this.props.params.accountId;

		this.state = {
			accountId: accountId,
			account: {},
			schedule: []
		};
		this.addClicked = this.addClicked.bind(this);
		this.handleMonthChecked = this.handleMonthChecked.bind(this);
		this.handleSelected = this.handleSelected.bind(this);
	}

	componentDidMount() {

		let _this = this;

		fetch(Utils.serviceUrl(`/accounts/${_this.state.accountId}`))
			.then(function (response) {
				response.json().then(function (data) {
					_this.setState({account: data});
				});
			});

		fetch(Utils.serviceUrl(`/accounts/${_this.state.accountId}/schedule`))
			.then(function (response) {
				response.json().then(function (data) {
					data.results.forEach(r => r.checked = false);
					_this.setState({
						schedule: data.results
					});
				});
			});

	}

	addClicked(event) {

		event.preventDefault();

		let _this = this;

		let request = {
			transactions: this.state.schedule.filter(s => s.checked).map(s => {
				delete s.checked;
				return s;
			})
		};

		fetch(Utils.serviceUrl(`/accounts/${_this.state.accountId}/batchTransactions`), {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(request)
		})
			.then(function (response) {
				window.location = `/accounts/${_this.state.accountId}/transactions`
			});


	}

	handleMonthChecked(event) {
		let month = event.currentTarget.dataset.id;
		let transactions = this.state.schedule;
		transactions.forEach(transaction => {
			let m = moment(transaction.date).format("MMM YYYY");
			if(m === month) {
				transaction.checked = event.target.checked;
			}
		});
		this.setState({
			schedule: transactions
		});
	}

	groupByMonth(schedule) {
		let result = {};
		schedule.forEach(transaction => {
			let month = moment(transaction.date).format("MMM YYYY");
			if(month in result) {
				result[month].push(transaction);
			} else {
				result[month] = [transaction];
			}
		});
		return result;
	}

	handleSelected(event) {
		let index = event.currentTarget.dataset.id;
		let schedule = this.state.schedule;
		let transaction = schedule[index];
		transaction.checked = event.target.checked;
		this.setState({
			schedule: schedule
		})
	}

	render() {
		return (
			<div>

				<div className="mb-4 border-bottom">
					<div className="actions float-right">
						<ButtonToolbar>
							<Button href={`/accounts/${this.state.accountId}/transactions`}>
								Cancel
							</Button>
							<Button variant="primary" onClick={this.addClicked}>
								Add
							</Button>
						</ButtonToolbar>
					</div>
					<h1>{this.state.account.name}</h1>
				</div>

				<div className="alert alert-info" role="alert">
					The following transactions will be added to the {this.state.account.name} account.
				</div>

				{Object.keys(this.groupByMonth(this.state.schedule)).map(month =>
				<div key={month}>
					<h4>{month}</h4>
					<Table striped hover responsive className="no-border">
						<thead>
						<tr>
							<th className="align-center width40">
								<FormCheck type='checkbox' data-id={month} onChange={this.handleMonthChecked}/>
							</th>
							<th className="width150">Date</th>
							<th>Payee</th>
							<th className="align-center width100">Out</th>
							<th className="align-center width100">In</th>
						</tr>
						</thead>
						<tbody>
						{this.groupByMonth(this.state.schedule)[month].map((transaction, index) =>
							<tr key={index}>
								<td className="align-center"><FormCheck type='checkbox' checked={transaction.checked} data-id={index} onChange={this.handleSelected}/></td>
								<td>{moment(transaction.date).format("YYYY-MM-DD")}</td>
								<td>{transaction.payee}</td>
								<td className="align-right">
									{transaction.amount < 0 && Utils.moneyFormatAmountFromInteger(-transaction.amount, this.state.account.currency)}
								</td>
								<td className="align-right">
									{transaction.amount > 0 && Utils.moneyFormatAmountFromInteger(transaction.amount, this.state.account.currency)}
								</td>
							</tr>
						)}
						</tbody>
					</Table>
				</div>
				)}

			</div>
		);
	}

}

export default withRouter(ScheduleContainer);
